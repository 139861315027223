import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useIntl } from 'react-intl';

import { AutoComplete, Form, Select } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import { FormInstance } from 'antd/lib';
import CommonsService from 'services/commonsService';

import CountryPreview from 'components/CountryPreview';

import useErrorCatch from 'hooks/useErrorCatch';

import getCountriesOptions from 'utils/getCountriesOptions';
import parseCity from 'utils/parseCity';

import { Country, CountryOption } from 'types/commons';

interface UseCountryCitySelectProps {
  form: FormInstance;
  countryFieldName?: string | string[];
  cityFieldName?: string | string[];
  isCountryRequired?: boolean;
  isCityRequired?: boolean;
  countries?: Country[];
  countryCode?: string;
  isCountriesLoading?: boolean;
}

const MIN_SPELLING_SYMBOLS = 3;

const useCountryCitySelect = ({
  form,
  countryFieldName = ['location', 'country'],
  cityFieldName = ['location', 'city'],
  isCountryRequired = false,
  isCityRequired = false,
  countries,
  countryCode,
  isCountriesLoading = false
}: UseCountryCitySelectProps): {
  countrySelect: JSX.Element;
  citySelect: JSX.Element;
  rawString: string;
} => {
  const intl = useIntl();
  const [cities, setCities] = useState<DefaultOptionType[]>([]);
  const [rawString, setRawString] = useState<string>('');
  const catchError = useErrorCatch();

  const country = countryCode || Form.useWatch(countryFieldName, form);

  const countriesOptions = useMemo(
    () => getCountriesOptions(countries),
    [countries]
  );

  useEffect(() => {
    setCities([]);
  }, [country]);

  const fetchCities = useCallback(
    async (spelling: string, country: string) => {
      if (spelling.length < MIN_SPELLING_SYMBOLS) {
        setCities([]);
        return;
      }

      try {
        const response = await CommonsService.getCitiesByCountryCodeAndSpelling(
          country,
          spelling
        );
        const newCities = response.map((city: string) => ({
          label: city,
          value: city,
          key: city
        }));
        setCities(newCities);
      } catch (error) {
        catchError({
          error,
          default: {
            message: 'Operation Failed',
            description: 'Failed to fetch Cities'
          }
        });
      }
    },
    [setCities]
  );

  const handleCitiesSearch = useCallback(
    (spelling: string) => {
      fetchCities(spelling, country);
    },
    [fetchCities, country]
  );

  const handleCitySelect = useCallback(
    (value: string) => {
      const parsedCity = parseCity(value);
      setRawString(value);
      form.setFieldValue(cityFieldName, parsedCity.city);
    },
    [form, cityFieldName]
  );

  const handleCountryChange = useCallback(() => {
    form.setFieldValue(cityFieldName, '');
    setRawString('');
  }, [form]);

  const renderCountryOption = ({
    data
  }: {
    data: CountryOption;
  }): ReactElement => (
    <CountryPreview countryCode={data.value} countryName={data.label} />
  );

  const renderCountryLabel = (option: DefaultOptionType): ReactNode => (
    <CountryPreview
      countryName={String(option.label)}
      countryCode={String(option.value)}
    />
  );

  const countrySelect = (
    <Form.Item
      name={countryFieldName}
      label={intl.formatMessage({ id: 'form.intake.countryOfLivingNew' })}
      rules={[{ required: isCountryRequired }]}
    >
      <Select
        onChange={handleCountryChange}
        placeholder={intl.formatMessage({
          id: 'form.intake.placeholder.countryOfLivingNew'
        })}
        options={countriesOptions}
        showSearch
        optionFilterProp="label"
        loading={isCountriesLoading}
        optionRender={renderCountryOption}
        labelRender={renderCountryLabel}
      />
    </Form.Item>
  );

  const citySelect = (
    <Form.Item
      name={cityFieldName}
      label="City/State"
      rules={[{ required: isCityRequired }]}
    >
      <AutoComplete
        options={cities}
        filterOption={false}
        onSearch={handleCitiesSearch}
        onSelect={handleCitySelect}
        value={form.getFieldValue(cityFieldName)}
        placeholder="Type or select a city and state"
        allowClear
      />
    </Form.Item>
  );

  return {
    countrySelect,
    citySelect,
    rawString
  };
};

export default useCountryCitySelect;
