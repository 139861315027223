import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MenuProps } from 'antd';
import { MODULE_SORT_CONFIG } from 'config/sortConfig';

import { AppDispatch } from 'app/store';

import {
  setPage,
  setSortField,
  setSortOrder
} from 'features/filters/filtersSlice';

import { scrollToTop } from 'utils/scrollToTop';

import { FIRST_PAGE_INDEX } from 'constants/common';

const useSortOptions = () => {
  const dispatch: AppDispatch = useDispatch();
  const config = MODULE_SORT_CONFIG.jobs;

  const [sortOption, setSortOption] = useState<string>(config?.defaultOption);

  const items: MenuProps['items'] = useMemo(() => {
    if (!config) return [];
    return Object.keys(config.options).map((key) => ({
      key,
      label: config.labels[key]
    }));
  }, [config]);

  const currentLabel = useMemo(() => {
    const currentItem = items.find((item) => item?.key === sortOption);
    // @ts-ignore
    return currentItem?.label || '';
  }, [items, sortOption]);

  const handleMenuClick = useCallback(
    (e: { key: string }) => {
      if (!config) return;
      const selectedOption = config.options[e.key];
      if (selectedOption) {
        setSortOption(e.key);
        dispatch(setSortField(selectedOption.field));
        dispatch(setSortOrder(selectedOption.order));
        dispatch(setPage(FIRST_PAGE_INDEX));
        scrollToTop();
      }
    },
    [dispatch, config]
  );

  useEffect(() => {
    if (config) {
      handleMenuClick({ key: config.defaultOption });
    }
  }, [config, handleMenuClick]);

  return {
    items,
    handleMenuClick,
    currentLabel,
    sortOption,
    value: {
      sortField: config?.options[sortOption]?.field,
      order: config?.options[sortOption]?.order
    }
  };
};

export { useSortOptions };
