import { RootState } from 'app/store';

import { isNil } from 'utils/isNil';

import { IGNORED_FILTERS_FOR_MARK } from 'constants/selectors';

export const selectFilters = (state: RootState) => state.filters;

export const selectAreFiltersSet = (state: RootState) => {
  const filters = selectFilters(state);

  return Boolean(
    Object.keys(filters).some((key) => {
      if (IGNORED_FILTERS_FOR_MARK.includes(key)) {
        return false;
      }

      const filterValue = filters[key as keyof typeof filters];

      return Array.isArray(filterValue)
        ? filterValue.length > 0
        : !isNil(filterValue) && filterValue !== '';
    })
  );
};
