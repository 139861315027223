import { ReactElement, ReactNode, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, useLocation } from 'react-router-dom';

import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import cs from 'classnames';
import Index from 'routes';

import AppFooter from 'components/Navigation/Footer';
import AppHeader from 'components/Navigation/Header';
import Sidebar from 'components/Sidebar';

import { selectShowSidebar } from 'features/sidebar/sidebarSlice';

import styles from './BaseLayout.module.scss';

interface BaseLayoutProps {
  children: ReactNode | ReactNode[] | ReactElement;
  hideHeader?: boolean;
  hideFooter?: boolean;
}

const BaseLayout = ({
  children,
  hideHeader = false,
  hideFooter = false
}: BaseLayoutProps): ReactElement => {
  const { pathname } = useLocation();
  const showSidebar = useSelector(selectShowSidebar);

  const currentRoute = useMemo(
    () => Index.find((route: RouteProps) => route.path === pathname),
    [pathname]
  );

  const HEADER = useMemo(
    () => (hideHeader ? null : <AppHeader />),
    [hideHeader]
  );
  const FOOTER = useMemo(
    () => (hideFooter ? null : <AppFooter />),
    [hideFooter]
  );

  return (
    <>
      <Layout
        className={cs(currentRoute?.key, styles.container, {
          [styles.active]: showSidebar
        })}
      >
        {HEADER}
        <Content>{children}</Content>
        {FOOTER}
      </Layout>
      <Sidebar />
    </>
  );
};

export default BaseLayout;
