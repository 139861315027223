import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { JobList, JobsState } from 'types/jobs';

import { RequestStatus } from 'constants/enums';

import { fetchJobsData } from './jobsThunks';

const initialState: JobsState = {
  jobs: [],
  isLoading: false,
  status: RequestStatus.Idle,
  error: null,
  count: 0
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobsData.pending, (state) => {
        state.status = RequestStatus.Loading;
        state.isLoading = true;
      })
      .addCase(
        fetchJobsData.fulfilled,
        (state, action: PayloadAction<JobList>) => {
          state.status = RequestStatus.Succeeded;
          state.jobs = action.payload.content;
          state.isLoading = false;
          state.count = action.payload.totalElements;
          state.error = null;
        }
      )
      .addCase(fetchJobsData.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  }
});

export default jobsSlice.reducer;
