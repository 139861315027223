import { FC } from 'react';

import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

import CountryPreview from 'components/CountryPreview';

import { CountryOption } from 'types/commons';

import SelectCommon from './SelectCommon';

const { Option } = Select;

interface CountrySelectProps {
  title: string;
  value: string | undefined;
  onChange: (value: string[]) => void;
  countries: CountryOption[];
  isLoading?: boolean;
  placeholder?: string;
}

const CountrySelect: FC<CountrySelectProps> = ({
  title,
  value,
  onChange,
  countries,
  isLoading = false,
  placeholder = 'Please enter the country'
}) => {
  const countriesOptions = countries.map((country) => (
    <Option key={country.value} value={country.value}>
      <CountryPreview countryCode={country.value} countryName={country.label} />
    </Option>
  ));

  const filterByCountryName = (input: string, option?: DefaultOptionType) => {
    return option?.props.children.props.countryName
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <SelectCommon
      title={title}
      // @ts-ignore
      value={value}
      placeholder={placeholder}
      customOptions={countriesOptions}
      onChange={onChange}
      filterOption={filterByCountryName}
      optionFilterProp="children"
      loading={isLoading}
      singleMode
    />
  );
};

export default CountrySelect;
