import { useCallback } from 'react';

import { App } from 'antd';
import { StatusCodes } from 'http-status-codes';

import { ErrorResponse } from 'types/api';

import { DEFAULT_NOTIFICATION_SHOW_DURATION_SEC } from 'constants/common';
import { ErrorMessageType } from 'constants/enums';
import { ACCESS_FORBIDDEN_MESSAGE_TEXT } from 'constants/notifications';

interface Message {
  message: string;
  description?: string;
}

export interface catchErrorProps {
  type?: ErrorMessageType;
  error?: unknown;
  isShowDefaultMessage?: boolean;
  default?: Message;
  messages?: Record<number, Message>;
  additionalErrorMessageMap?: Record<number, Message>;
  consoleMessage?: string;
  duration?: number;
}

const ADDITIONAL_REQUIRED_NOTIFICATIONS_MAP: Record<number, Message> = {
  [StatusCodes.FORBIDDEN]: { message: ACCESS_FORBIDDEN_MESSAGE_TEXT }
};

const useErrorCatch = () => {
  const { notification } = App.useApp();

  const catchError = useCallback(
    ({
      type = ErrorMessageType.Error,
      error,
      isShowDefaultMessage = true,
      default: defaultMessage,
      consoleMessage,
      messages,
      additionalErrorMessageMap,
      duration = DEFAULT_NOTIFICATION_SHOW_DURATION_SEC
    }: catchErrorProps) => {
      if (consoleMessage) {
        console.error(consoleMessage);
      }

      //Set defaultMessage as message for notification
      let notificationMessage = defaultMessage;

      //If no defaultMessage set error.message (if error exists) as message
      if (error && !notificationMessage) {
        notificationMessage = { message: (error as ErrorResponse).message };
      }

      //If error code in errorMessageMap - set this massage instead of defaultMessage
      if (error && messages?.[(error as ErrorResponse).status]) {
        notificationMessage = messages[(error as ErrorResponse).status];
      }

      if (isShowDefaultMessage && notificationMessage) {
        notification[type]({
          ...notificationMessage,
          placement: 'topRight',
          duration
        });
      }

      // Additional error message show if have status in
      // additionalErrorMessageMap or ADDITIONAL_REQUIRED_NOTIFICATIONS_MAP
      if (error) {
        const additionalMessage =
          additionalErrorMessageMap?.[(error as ErrorResponse).status] ||
          ADDITIONAL_REQUIRED_NOTIFICATIONS_MAP[
            (error as ErrorResponse).status
          ];

        if (additionalMessage) {
          notification[type]({
            ...additionalMessage,
            placement: 'topRight',
            duration
          });
        }
      }
    },
    [notification]
  );

  return catchError;
};

export default useErrorCatch;
