import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import authReducer from 'features/auth/authSlice';
import dictionariesReducer from 'features/dictionaries/dictionariesSlice';
import filtersReducer from 'features/filters/filtersSlice';
import jobsReducer from 'features/jobs/jobsSlice';
import localeReducer from 'features/locale/localeSlice';
import sidebarReducer from 'features/sidebar/sidebarSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    locale: localeReducer,
    dictionaries: dictionariesReducer,
    sidebar: sidebarReducer,
    filters: filtersReducer,
    jobs: jobsReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
