import { FC } from 'react';
import { useSelector } from 'react-redux';

import Tag from 'components/Tag/Tag';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import getLabelByValue from 'utils/getLabelByValue';

import { Dictionaries } from 'constants/enums';

import styles from './JobIndustry.module.scss';

interface JobIndustryProps {
  industry: string;
}

const JobIndustry: FC<JobIndustryProps> = ({ industry }) => {
  const industriesDict = useSelector(selectDictionary(Dictionaries.Industry));

  const label = getLabelByValue(industriesDict, industry) || '—';

  return <Tag className={styles.industryContainer} label={label} big />;
};

export default JobIndustry;
