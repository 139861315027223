import { Gutter } from 'antd/es/grid/row';

export const DEFAULT_NOTIFICATION_SHOW_DURATION_SEC = 3;
export const TABLE_PAGING_VALUES = [10, 20, 50];
export const FIRST_PAGE_INDEX = 0;
export const PAGE_SIZE = 21;
export const MAX_PAGE_SIZE = 100;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const MINIMAL_PASSWORD_LENGTH = 8;
export const SAVE_FILTERS_DEBOUNCE_TIMEOUT_MS = 5000;
export const TYPE_INPUT_DEBOUNCE_TIMEOUT_MS = 500;
export const AUTO_SAVE_INPUT_DEBOUNCE_TIMEOUT_MS = 1000;
export const FALLBACK_VALUE = '-';
export const SORT_BY_CREATED_DATE = 'createdDate';
export const SORT_BY_EDIT_DATE = 'editDate';
export const LANGUAGE_LEVEL_SPLITTER = ' - ';
export const SECTION_ROW_GUTTER = [16, 16] as [Gutter, Gutter];
export const MIN_SEARCH_LENGTH = 3;
export const SWITCH_OPTIONS = {
  YES: 'yes',
  NO: 'no'
};
export const MAX_ITEMS_TO_GET = 10000;
export const GDPR_SETTINGS = 'cnst';
export const COPYRIGHT_YEAR = 2024;

export const LEGAL_BASE_URL = 'https://joblio.co';
export const PRIVACY_PATH = `${LEGAL_BASE_URL}/privacy-policy`;
export const TERMS_PATH = `${LEGAL_BASE_URL}/terms-of-use`;
export const COOKIE_PATH = `${LEGAL_BASE_URL}/cookie-policy`;
export const DATA_PRIVACY_PATH = `${LEGAL_BASE_URL}/notice-exercising-your-data-privacy-rights`;

export const MIN_PASSWORD_LENGTH = 8;

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

export const GDPROptions = {
  ANALYTICS: 'A',
  FUNCTIONAL_COOKIES: 'F',
  TARGETING_COOKIES: 'T'
};

export const PHONE_STATUS = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED'
};
