import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from 'utils/api/api';

import { USERS_ME } from 'constants/endpoints';

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get(USERS_ME);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
