import { createSlice } from '@reduxjs/toolkit';

import { DictionaryState } from 'types/dictionaries';

import { RequestStatus } from 'constants/enums';

import { fetchDictionary } from './dictionariesThunks';

const initialState: DictionaryState = {
  data: {},
  status: RequestStatus.Idle,
  error: null
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDictionary.pending, (state) => {
        state.status = RequestStatus.Loading;
      })
      .addCase(fetchDictionary.fulfilled, (state, action) => {
        state.status = RequestStatus.Succeeded;
        state.data[action.payload.dict] = action.payload.data;
      })
      .addCase(fetchDictionary.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        state.error = action.payload as string;
      });
  }
});

export default dictionariesSlice.reducer;
