import { FC } from 'react';

import dayjs from 'dayjs';

import Tag from 'components/Tag/Tag';

interface NewTagProps {
  createdDate: string;
  editDate?: string | null;
}

const NewTag: FC<NewTagProps> = ({ createdDate, editDate }) => {
  const now = dayjs();
  const baseDate =
    editDate && dayjs(editDate).isAfter(dayjs(createdDate))
      ? dayjs(editDate)
      : dayjs(createdDate);

  const isNew = now.diff(baseDate, 'day') < 7;

  return isNew ? <Tag label="new" /> : null;
};

export default NewTag;
