import { useSelector } from 'react-redux';

import CountrySelect from 'components/Filters/components/CountrySelect';
import SelectCommon from 'components/Filters/components/SelectCommon';
import SelectedFiltersTags from 'components/Filters/components/SelectedFiltersTags';
import useHandleChange from 'components/Filters/hooks/useHandleChange';
import SortDropdown from 'components/SortDropdown/SortDropdown';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';
import { selectFilters } from 'features/filters/filtersSelectors';
import { setCountry, setIndustry } from 'features/filters/filtersSlice';

import formatOptionsWithLabel from 'utils/formatOptionsWithLabel';
import getCountriesOptions from 'utils/getCountriesOptions';

import { Dictionaries } from 'constants/enums';

import styles from './Filters.module.scss';

const Filters = () => {
  const { country, industry } = useSelector(selectFilters);

  const industries = useSelector(selectDictionary(Dictionaries.Industry));
  const industriesOptions = formatOptionsWithLabel(industries);

  const countriesOptions = getCountriesOptions();

  const handleChange = useHandleChange<string | undefined>();
  const handleArrayChange = useHandleChange<string[]>();
  const handleCountryChange = handleChange(setCountry);
  const handleIndustryChange = handleArrayChange(setIndustry);
  return (
    <div className={styles.filtersMainContainer}>
      <SortDropdown />
      <div className={styles.filtersContainer}>
        <CountrySelect
          title="Country"
          value={country}
          // @ts-ignore
          onChange={handleCountryChange}
          placeholder="Please select country"
          countries={countriesOptions}
        />
        <SelectCommon
          title="Industry"
          value={industry}
          options={industriesOptions}
          onChange={handleIndustryChange}
          placeholder="Please select industry"
        />
      </div>
      <SelectedFiltersTags industries={industriesOptions} />
    </div>
  );
};

export default Filters;
