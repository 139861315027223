import { ReactElement } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

import styles from './LoadingOverlay.module.scss';

const LoadingOverlay = ({
  isLoading
}: {
  isLoading: boolean;
}): ReactElement | null =>
  isLoading ? (
    <div className={styles.LoadingOverlay}>
      <LoadingIndicator />
    </div>
  ) : null;

export default LoadingOverlay;
