import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from 'utils/api/api';
import formatDictionaryData from 'utils/formatDictionaryData';

import { DICT } from 'constants/endpoints';

export const fetchDictionary = createAsyncThunk(
  'dictionaries/fetchDictionary',
  async (dict: string, { rejectWithValue }) => {
    try {
      const response = await get(`${DICT}/${dict}`, {}, true);
      return {
        dict: response.name,
        data: formatDictionaryData(response.data)
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
