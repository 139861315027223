import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ExtraHeader.module.scss';

interface ExtraHeaderProps {
  title: string;
  description?: string;
}

const ExtraHeader: FC<ExtraHeaderProps> = ({
  title,
  description
}): ReactElement => (
  <div className={styles.container}>
    <div className={styles.containerInner}>
      <div className={styles.title}>
        <FormattedMessage id={title} />
      </div>
      {description && (
        <div className={styles.description}>
          <FormattedMessage id={description} />
        </div>
      )}
    </div>
  </div>
);

export default ExtraHeader;
