import { FC } from 'react';
import { useSelector } from 'react-redux';

import cs from 'classnames';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import getLabelByValue from 'utils/getLabelByValue';

import { Dictionaries } from 'constants/enums';

import styles from './Salary.module.scss';

interface SalaryProps {
  actualSalary: string | null;
  payrollRates: string | null;
  className?: string;
}

const Salary: FC<SalaryProps> = ({ actualSalary, payrollRates, className }) => {
  const payrollRatesOptions = useSelector(
    selectDictionary(Dictionaries.PayrollRates)
  );

  const renderSalary = () => {
    if (actualSalary) {
      return `${actualSalary}${payrollRates ? ` ${getLabelByValue(payrollRatesOptions, payrollRates)}` : ''}`;
    }
    return '-';
  };

  return (
    <span className={cs(styles.container, className)}>{renderSalary()}</span>
  );
};

export default Salary;
