import { FC, ReactNode } from 'react';

import { Select, Spin } from 'antd';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';

import { FormattedOption } from 'types/dictionaries';

import styles from './SelectCommon.module.scss';

const { Option } = Select;

interface SelectCommonProps extends SelectProps<string[]> {
  title?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  options?: FormattedOption[];
  loading?: boolean;
  singleMode?: boolean;
  customOptions?: ReactNode;
  errorText?: string;
  isSearchByLabel?: boolean;
  disabledOptionsValues?: string[];
  onSearch?: (value: string) => void;
}

const SelectCommon: FC<SelectCommonProps> = ({
  title,
  value,
  onChange,
  options,
  loading = false,
  singleMode = false,
  customOptions,
  status,
  errorText,
  isSearchByLabel = true,
  disabledOptionsValues = [],
  ...props
}) => {
  const searchByLabel = (input: string, option?: DefaultOptionType) =>
    option?.props?.children?.toLowerCase().includes(input.toLowerCase());

  const handleChange = (value: string[]): void => {
    onChange?.(value);
  };

  return (
    <div className={styles.selectContainer}>
      {title && <div className={styles.title}>{title}</div>}
      <Select
        mode={singleMode ? undefined : 'multiple'}
        className={styles.select}
        maxTagCount="responsive"
        value={options?.length || !!customOptions ? value : []}
        onChange={handleChange}
        loading={loading}
        filterOption={isSearchByLabel ? searchByLabel : false}
        status={status}
        notFoundContent={
          loading ? (
            <div className="select-spinner-container">
              <Spin size="default" />
            </div>
          ) : null
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        {...props}
      >
        {customOptions ||
          (options &&
            options.map(({ value, label }) => (
              <Option
                key={value}
                value={value}
                disabled={disabledOptionsValues.includes(value)}
              >
                {label}
              </Option>
            )))}
      </Select>
      {status === 'error' && errorText && (
        <div className="select-error-text">{errorText}</div>
      )}
    </div>
  );
};

export default SelectCommon;
