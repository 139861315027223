import { get, post, postFormData } from 'utils/api/api';

import {
  Application,
  CreateApplication,
  PreScreeningEmail
} from 'types/application';
import { Jobseeker } from 'types/jobseekers';

import { APPLICATIONS, JOBSEEKERS, USERS } from 'constants/endpoints';

const ApplicationService = {
  getApplication: async (id: string): Promise<Application> => {
    const res = await get(`${APPLICATIONS}/${id}`);
    return res;
  },

  postCreateApplication: async (
    payload: CreateApplication
  ): Promise<Application> => {
    const res = await post(APPLICATIONS, payload);
    return res;
  },

  postIntakePassedCheck: async (jobseekerId: string): Promise<Application> => {
    const res = await post(`${USERS}/${jobseekerId}/intake-passed`, {});
    return res;
  },

  postIntakeForm: async (data: Partial<Jobseeker>): Promise<void> => {
    await post(JOBSEEKERS, data);
  },

  postPreScreeningFormData: async (
    jobseekerId: string,
    jobId: string,
    formData: FormData
  ): Promise<void> => {
    await postFormData(
      `${JOBSEEKERS}/${jobseekerId}/${jobId}/pre-screening`,
      formData
    );
  },

  postPreScreeningEmail: async (
    jobseekerId: string,
    data: PreScreeningEmail
  ): Promise<void> => {
    await post(`${USERS}/${jobseekerId}/pre-screening/start`, data);
  }
};

export default ApplicationService;
