import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Row } from 'antd';

import ExtraHeader from 'components/ExtraHeader';
import JobsList from 'components/Job/JobsList';
import LoadingIndicator from 'components/LoadingIndicator';

import { useGetResource } from 'hooks/useGetResource';

import { SortOrders } from 'types/filters';
import { Job } from 'types/jobs';

import { SORT_BY_EDIT_DATE } from 'constants/common';
import { JOBS as GET_JOBS } from 'constants/endpoints';
import { JOBS } from 'constants/pathNames';

import { ReactComponent as Icon } from 'assets/icons/thank-you-check.svg';

import styles from './QuestionnaireThankYouPage.module.scss';

const QuestionnaireThankYouPage = (): ReactElement => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const message = state?.thankYouMessage ?? 'screening.thankYou.default';

  const { data, isLoading } = useGetResource<Job>({
    url: GET_JOBS,
    additionalFilters: {
      sortField: SORT_BY_EDIT_DATE,
      order: SortOrders.Desc,
      industry: state.jobIndustry
    }
  });

  const jobs = useMemo(() => {
    if (!data) return [];
    const shuffled = [...data].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  }, [data]);

  return isLoading || !jobs.length ? (
    <LoadingIndicator />
  ) : (
    <>
      <ExtraHeader title="screening.header.title" />
      <div className="container">
        <div className={styles.messageContainer}>
          <Icon />
          <FormattedMessage id={message} />
        </div>
        {jobs.length > 0 && (
          <div className={styles.suggestedJobs}>
            <Row gutter={[24, 24]} className={styles.jobsContainer}>
              <JobsList jobs={jobs} />
            </Row>
            <p className="text-center">
              <Button
                type="primary"
                className={`btn btn-primary ${styles.button}`}
                onClick={() => navigate(JOBS)}
              >
                <FormattedMessage id="job.browseMoreJobs" />
              </Button>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionnaireThankYouPage;
