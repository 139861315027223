export enum LanguageLevel {
  Native = 'NATIVE',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED'
}

export enum AuthSteps {
  CheckEmail = 'CHECK EMAIL',
  Login = 'LOGIN',
  Registration = 'REGISTRATION',
  ForgotPassword = 'FORGOT PASSWORD'
}

export enum ErrorMessageType {
  Error = 'error',
  Warning = 'warning'
}

export enum AuthMessages {
  IncorrectPassword = 'Bad credentials'
}

export enum Dictionaries {
  Language = 'LANGUAGE',
  JobStatus = 'JOB_STATUS',
  Industry = 'INDUSTRY',
  ClientCountry = 'CLIENT_COUNTRY',
  LanguageLevel = 'LANGUAGE_LEVEL',
  Skillset = 'SKILLSET',
  ApplicantStatus = 'APPLICANT_STATUS',
  ClientPrivatePublic = 'CLIENT_PRIVATE_PUBLIC',
  ClientStatus = 'CLIENT_STATUS',
  ClientDomesticOver = 'CLIENT_DOMESTIC_OVER',
  ClientYearsInBus = 'CLIENT_YEARS_IN_BUS',
  JobType = 'JOB_TYPE',
  PayrollRates = 'PAYROLL_RATES',
  JobseekerStage = 'JOBSEEKER_STAGE',
  Education = 'EDUCATION',
  JobShift = 'JOB_SHIFT',
  ImmigrationRoutes = 'IMMIGRATION_ROUTES',
  SourcePlatform = 'SOURCE_PLATFORM',
  JobseekerStageTag = 'JOBSEEKER_STAGE_TAG',
  ApplicationStage = 'APPLICATION_STAGE',
  ApplicationStageTag = 'APPLICATION_STAGE_TAG',
  JobFeeStructure = 'JOB_FEE_STRUCTURE'
}

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed'
}

export enum JobAttachmentTypes {
  TaoContract = 'TAO_CONTRACT',
  ExtraPaperWork = 'EXTRA_PAPERWORK',
  JobImage = 'JOB_IMAGE'
}

export enum UserAttachmentTypes {
  UserAvatar = 'USER_AVATAR'
}

export enum JobseekerAttachmentTypes {
  OfferLetter = 'OFFER_LETTER',
  Passport = 'PASSPORT',
  IdCard = 'ID_CARD',
  Visa = 'VISA',
  WorkPermit = 'WORK_PERMIT',
  CV = 'CV',
  UserPrivateDocument = 'USER_PRIVATE_DOCUMENT',
  DriverLicense = 'DRIVER_LICENSE'
}

export enum JobFeeStructures {
  Ace = 'ACE',
  DirectHire = 'DIRECT_HIRE',
  HourlyMarkup = 'HOURLY_MARKUP'
}

export enum MimeTypes {
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf = 'application/pdf',
  jpg = 'image/jpeg',

  jpeg = 'image/jpeg',
  png = 'image/png'
}

export enum JobTypes {
  Contractor = 'CONTRACTOR',
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Remote = 'REMOTE',
  Seasonal = 'SEASONAL'
}

export enum JobseekerStage {
  AllJobseekerStage = 'ALL_JOBSEEKER_STAGE',
  ActiveCandidateJobseekerStage = 'ACTIVE_CANDIDATE_JOBSEEKER_STAGE',
  ProcessingJobseekerStage = 'PROCESSING_JOBSEEKER_STAGE',
  ClosedHireJobseekerStage = 'CLOSED_HIRE_JOBSEEKER_STAGE',
  TerminatedJobseekerStage = 'TERMINATED_JOBSEEKER_STAGE',
  ToClarifyJobseekerStage = 'TO_CLARIFY_JOBSEEKER_STAGE',
  InactiveJobseekerStage = 'INACTIVE_JOBSEEKER_STAGE',
  WaitingListJobseekerStage = 'WAITING_LIST_JOBSEEKER_STAGE'
}

export enum JobseekerTags {
  ClosedHireJobseekerStageOfferLetterSigned = 'CLOSED_HIRE_JOBSEEKER_STAGE_OFFER_LETTER_SIGNED',
  ClosedHireJobseekerStageOnSite = 'CLOSED_HIRE_JOBSEEKER_STAGE_ON_SITE',
  ClosedHireJobseekerStageReplacement = 'CLOSED_HIRE_JOBSEEKER_STAGE_REPLACEMENT',
  InactiveJobseekerStageUnder18 = 'INACTIVE_JOBSEEKER_STAGE_UNDER_18',
  InactiveJobseekerStageNotEligible = 'INACTIVE_JOBSEEKER_STAGE_NOT_ELIGIBLE',
  InactiveJobseekerStageNotInterested = 'INACTIVE_JOBSEEKER_STAGE_NOT_INTERESTED',
  InactiveJobseekerStageUnresponsive = 'INACTIVE_JOBSEEKER_STAGE_UNRESPONSIVE',
  InactiveJobseekerStageRiskProfile = 'INACTIVE_JOBSEEKER_STAGE_RISK_PROFILE',
  ProcessingJobseekerStageClientInterview = 'PROCESSING_JOBSEEKER_STAGE_CLIENT_INTERVIEW',
  ProcessingJobseekerStageInternalInterview = 'PROCESSING_JOBSEEKER_STAGE_INTERNAL_INTERVIEW',
  TerminatedJobseekerStageQuit = 'TERMINATED_JOBSEEKER_STAGE_QUIT',
  TerminatedJobseekerStageLaidOff = 'TERMINATED_JOBSEEKER_STAGE_LAID_OFF'
}

export enum Departments {
  Clients = 'CLIENTS'
}

export enum ProviderType {
  Local = 'local',
  No = 'no'
}

export enum PDFTitles {
  Availability = 'Availability',
  Experience = 'Experience',
  Location = 'Location & Relocation',
  Legal = 'Legal & Work Authorization',
  SalaryExpectations = 'Salary Expectations',
  AvailabilityForInterview = 'Availability for Interview'
}
