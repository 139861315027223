// Only 2 default countries, do not change unless the business requirements are changed

const countriesData = [
  { name: 'Canada', code: 'CA', longCode: 'CAN' },
  { name: 'United States', code: 'US', longCode: 'USA' }
];

const COUNTRIES_ORDER = ['United States', 'Canada'];

export default countriesData;
export { COUNTRIES_ORDER };
