import { FormattedOption } from 'types/dictionaries';

const formatOptionsWithLabel = (
  options: FormattedOption[]
): FormattedOption[] => {
  return options.map((option) => ({
    ...option,
    label: option.label
  }));
};

export default formatOptionsWithLabel;
