import { FC, ReactElement, useContext, useState } from 'react';
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Col, Row } from 'antd';

import { toggleSidebar } from 'features/sidebar/sidebarSlice';

import styles from '../Authentication.module.scss';

interface CheckEmailProps {
  handleSubmit: () => void;
  canSubmit: boolean;
}

const CheckEmail: FC<CheckEmailProps> = ({
  handleSubmit,
  canSubmit
}): ReactElement => {
  const dispatch = useDispatch();
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const key = import.meta.env.VITE_RECAPTCHA_KEY;

  const onCaptchaChange = (value: string | null): void =>
    setCaptchaValue(value);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ReCAPTCHA
          sitekey={key}
          className={styles.captcha}
          onChange={onCaptchaChange}
        />
      </Col>
      <Col span={12} className="text-center">
        <Button
          type="link"
          className="btn"
          onClick={() => dispatch(toggleSidebar())}
        >
          <FormattedMessage id="form.button.cancel" />
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          className="btn btn-primary"
          disabled={!canSubmit || !captchaValue}
          onClick={handleSubmit}
          block
        >
          <FormattedMessage id="form.control.continue" />
        </Button>
      </Col>
    </Row>
  );
};

export default CheckEmail;
