import { Select } from 'antd';

import { FormattedOption } from 'types/dictionaries';

import formatOptionParentKey from './formatOptionParentKey';

const { Option, OptGroup } = Select;

interface getOptionsGroupedByParentKeyProps {
  itemsList: any[];
  labelKey: string[];
  valueKey: string[];
  parentKey: string[];
  isFormatParentKey?: boolean;
}

const OTHER_GROUP_KEY = 'Other';

export const getOptionsGroupedByParentKey = ({
  itemsList,
  labelKey,
  valueKey,
  parentKey,
  isFormatParentKey = true
}: getOptionsGroupedByParentKeyProps) => {
  const formattedItems = itemsList
    .map((item) => ({
      label: labelKey.reduce((obj, prop) => obj && obj[prop], item),
      parentKey:
        parentKey.reduce((obj, prop) => obj && obj[prop], item) ||
        OTHER_GROUP_KEY,
      value: valueKey.reduce((obj, prop) => obj && obj[prop], item)
    }))
    .reduce<Record<string, FormattedOption[]>>((acc, option) => {
      const formattedKey = isFormatParentKey
        ? formatOptionParentKey(option.parentKey || '')
        : option.parentKey || '';
      if (!acc[formattedKey]) {
        acc[formattedKey] = [];
      }
      acc[formattedKey].push(option);
      return acc;
    }, {});

  return Object.keys(formattedItems).map((parentKey) => (
    <OptGroup key={parentKey} label={parentKey}>
      {formattedItems[parentKey].map((option: FormattedOption) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </OptGroup>
  ));
};
