import { SortOrders } from 'types/filters';
import { ModuleSortConfig, SortOption } from 'types/sorting';

const createSortOption = (field: string, order: SortOrders): SortOption => ({
  field,
  order
});

export const MODULE_SORT_CONFIG: { [key: string]: ModuleSortConfig } = {
  jobs: {
    options: {
      EDIT_DATE_DESC: createSortOption('editDate', SortOrders.Desc),
      EDIT_DATE_ASC: createSortOption('editDate', SortOrders.Asc),
      // CREATED_DATE_ASC: createSortOption('createdDate', SortOrders.Asc),
      // CREATED_DATE_DESC: createSortOption('createdDate', SortOrders.Desc),
      NAME_ASC: createSortOption('title', SortOrders.Asc),
      NAME_DESC: createSortOption('title', SortOrders.Desc)
    },
    labels: {
      EDIT_DATE_DESC: 'Newest first',
      EDIT_DATE_ASC: 'Oldest first',
      // CREATED_DATE_ASC: 'Date: Newest first',
      // CREATED_DATE_DESC: 'Date: Oldest first',
      NAME_ASC: 'Job Name (A-Z)',
      NAME_DESC: 'Job Name (Z-A)'
    },
    defaultOption: 'EDIT_DATE_DESC'
  },
  default: {
    options: {},
    labels: {},
    defaultOption: ''
  }
};
