import { RootState } from 'app/store';

import { FormattedOption } from 'types/dictionaries';

export const selectAllDictionaries = () => (state: RootState) =>
  state.dictionaries.data;

export const selectDictionary =
  (dict: string) =>
  (state: RootState): FormattedOption[] =>
    state.dictionaries.data[dict] || [];

export const selectDictionariesStatus = (state: RootState): string =>
  state.dictionaries.status;

export const selectDictionariesError = (state: RootState): string | null =>
  state.dictionaries.error;
