import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Form, notification } from 'antd';
import ApplicationService from 'services/applicationService';

import IntakeForm from 'components/IntakeForm';

import { selectUser, selectUserId } from 'features/auth/authSlice';

import { Jobseeker } from 'types/jobseekers';

import { INTAKE_THANK_YOU } from 'constants/pathNames';

import styles from './IntakePage.module.scss';

const IntakePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);

  const selectedJobId = location.state?.jobId;
  const jobseekerId = useSelector(selectUserId);
  const email = useSelector(selectUser)?.email;
  const sourcePlatform = 'join.joblio intake';

  const handleSubmitForm = async (
    formFields: Partial<Jobseeker>
  ): Promise<void> => {
    setLoadingButton(true);

    try {
      await submitIntakeForm(formFields);

      if (selectedJobId) {
        const applicationResponse = await createApplicationRecord(
          jobseekerId,
          selectedJobId
        );
        navigateToQuestionnaire(applicationResponse.id);
      } else {
        navigate(INTAKE_THANK_YOU);
      }
    } catch (error) {
      handleSubmissionError(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const submitIntakeForm = async (formFields: Record<string, unknown>) => {
    const fields = {
      ...formFields,
      sourcePlatform
    };
    await ApplicationService.postIntakeForm(fields);
  };

  const createApplicationRecord = async (
    jobseekerId: string,
    jobId: string
  ) => {
    return ApplicationService.postCreateApplication({ jobseekerId, jobId });
  };

  const navigateToQuestionnaire = (applicationRecordId: string) => {
    navigate(
      `/questionnaire/${selectedJobId}/${jobseekerId}/${applicationRecordId}`,
      { replace: true }
    );
    notification.success({
      message: "You've successfully applied for a job",
      placement: 'topRight',
      duration: 5
    });
  };

  const handleSubmissionError = (error: unknown) => {
    console.error(error);
    notification.error({
      message: 'Failed to process intake form',
      description: 'Please try again later',
      placement: 'topRight',
      duration: 5
    });
  };

  return (
    <div className="roundedContainer">
      <div className={styles.content}>
        <h2 className="title">
          <FormattedMessage id="intake.contentTitle" />
        </h2>
        <p className={styles.textMargin}>
          <FormattedMessage id="intake.contentText" />
        </p>
        <IntakeForm
          form={form}
          onFormSubmit={handleSubmitForm}
          loading={loadingButton}
          initialValues={{ email }}
        />
      </div>
    </div>
  );
};

export default IntakePage;
