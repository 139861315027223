import { FC, ReactElement } from 'react';

import cs from 'classnames';
import * as Flags from 'country-flag-icons/react/3x2';
import { FlagComponent } from 'country-flag-icons/react/3x2';

import { Location } from 'types/commons';

import styles from './LocationDisplay.module.scss';

interface LocationDisplayProps {
  location: Location;
  className?: string;
  noFlag?: boolean;
}

const LocationDisplay: FC<LocationDisplayProps> = ({
  location,
  className,
  noFlag = false
}): ReactElement | null => {
  const getCountryFlag = (label: string): FlagComponent | null => {
    const flagMap: Record<string, FlagComponent> = {
      USA: Flags.US,
      Canada: Flags.CA,
      Lithuania: Flags.LT,
      Israel: Flags.IL
    };

    return flagMap[label] || null;
  };

  if (!location?.country) return null;

  const { country, state, city } = location;

  const Flag = getCountryFlag(country);

  return (
    <div className={cs(styles.container, className)}>
      {!noFlag && Flag && <Flag className={styles.countryCode} />}
      {[city, state, country].filter(Boolean).join(', ')}
    </div>
  );
};

export default LocationDisplay;
