import { ReactElement, memo } from 'react';

import JobCard from 'components/Job/JobCard/JobCard';

import { Job } from 'types/jobs';

const JobsList = ({ jobs }: { jobs: Job[] }): ReactElement[] | ReactElement =>
  jobs.map((job: Job) => <JobCard job={job} key={job.id} />);

export default memo(JobsList);
