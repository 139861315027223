import { useEffect, useState } from 'react';

import { isEqual } from 'lodash';

import { generateParams } from 'features/filters/filtersSlice';

import { get } from 'utils/api/api';

import { MAX_ITEMS_TO_GET } from 'constants/common';

const useGetResource = function <T>({
  url,
  isDisableLoad = false,
  isOpenApi = false,
  additionalFilters
}: {
  url: string;
  isDisableLoad?: boolean;
  isOpenApi?: boolean;
  additionalFilters?: Record<string, unknown>;
}): {
  data: T[];
  isLoading: boolean;
} {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [localFilters, setLocalFilters] = useState<
    Record<string, unknown> | undefined
  >(additionalFilters);

  const getData = async (url: string) => {
    try {
      setIsLoading(true);

      const filters = {
        page: 0,
        pageSize: MAX_ITEMS_TO_GET,
        ...(localFilters && { ...localFilters })
      };

      const params = generateParams(filters);

      const response = await get(`${url}${params}`, {}, isOpenApi);

      if (isOpenApi) {
        setData(response);
        return;
      }

      setData(response.content);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isDisableLoad) {
      return;
    }
    getData(url);
  }, [url, isDisableLoad]);

  // to prevent trigger load on additionalFilters object link change
  useEffect(() => {
    setLocalFilters((currentLocalFilters) => {
      if (isEqual(currentLocalFilters, additionalFilters)) {
        return currentLocalFilters;
      }

      return additionalFilters;
    });
  }, [additionalFilters]);

  return { data, isLoading };
};

export { useGetResource };
