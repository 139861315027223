import checkStatus from 'utils/api/checkStatus';
import errorParser from 'utils/api/errorParser';
import responseParser from 'utils/api/responseParser';

import { HOST } from 'constants/api';

import { getHeaders } from './getHeaders';

const postApiData = async (path: string, body: any): Promise<any> => {
  const headers = getHeaders();
  const url = `${HOST}${path}`;

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  })
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export default postApiData;
