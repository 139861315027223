export const JOIN = '/join';
export const OPEN = '/open';
export const COUNTRIES = `${OPEN}/countries`;
export const CITIES = `${OPEN}/cities`;
export const DICT = `${OPEN}/dict`;

export const JOBS = `${JOIN}/jobs`;
export const JOB = `${JOIN}/job`;

export const JOBSEEKERS = `${JOIN}/jobseekers`;

export const APPLICATIONS = `${JOIN}/applications`;

export const USERS = '/users';
export const USERS_ME = `${USERS}/me`;

export const AUTH = '/auth';
export const CHECK_EMAIL = `${AUTH}/checkEmail`;
export const LOGIN = `${AUTH}/login`;
