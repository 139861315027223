import { FormattedOption } from 'types/dictionaries';

const getSingleLabelFromDictionary = (
  dictionary: FormattedOption[],
  value: string | null
): string => {
  if (!value) {
    return '';
  }
  const found = dictionary.find((option) => option.value === value);
  return found ? (found.label as string) : '';
};

export default getSingleLabelFromDictionary;
