import { FC } from 'react';
import { useSelector } from 'react-redux';

import cs from 'classnames';

import Tag from 'components/Tag/Tag';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import formatToCamelCase from 'utils/formatToCamelCase';
import getLabelByValue from 'utils/getLabelByValue';

import { Dictionaries, JobTypes } from 'constants/enums';

import styles from './JobType.module.scss';

interface JobTypeProps {
  jobType: JobTypes;
}

const JobType: FC<JobTypeProps> = ({ jobType }) => {
  const jobTypesOptions = useSelector(selectDictionary(Dictionaries.JobType));

  const tagClass = cs(styles[formatToCamelCase(jobType)]);
  const label = getLabelByValue(jobTypesOptions, jobType)?.toLowerCase() || '—';

  return <Tag className={tagClass} label={label} />;
};

export default JobType;
