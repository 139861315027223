import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Checkbox, Col, Form, FormInstance, Input, Row } from 'antd';

import TermsAndConditions from 'components/TermsAndConditions';

import { toggleSidebar } from 'features/sidebar/sidebarSlice';

import { MIN_PASSWORD_LENGTH } from 'constants/common';

import VerificationCode from '../VerificationCode';

interface RegistrationProps {
  handleSubmit: () => void;
  canSubmit: boolean;
  form: FormInstance;
}

const Registration: FC<RegistrationProps> = ({
  form,
  handleSubmit,
  canSubmit
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userEmail = form.getFieldValue('email');

  // @ts-ignore
  const validateCheckbox = (rule, value): Promise<string | void> => {
    if (!value) {
      return Promise.reject(new Error('Field is required'));
    }
    return Promise.resolve();
  };

  const validatePassword = (_: any, value: any): Promise<void> => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(
        new Error(intl.formatMessage({ id: 'validation.confirmPassword' }))
      );
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <VerificationCode email={userEmail} />
      </Col>
      <Col span={24}>
        <Form.Item
          name="password"
          label={intl.formatMessage(
            { id: 'form.password' },
            { minLength: MIN_PASSWORD_LENGTH }
          )}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="validation.password"
                  values={{ minLength: MIN_PASSWORD_LENGTH }}
                />
              )
            },
            {
              min: MIN_PASSWORD_LENGTH,
              message: (
                <FormattedMessage
                  id="validation.password.length"
                  values={{ minLength: MIN_PASSWORD_LENGTH }}
                />
              )
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="confirm"
          label={intl.formatMessage(
            { id: 'form.confirmPassword' },
            { minLength: MIN_PASSWORD_LENGTH }
          )}
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please, confirm the password' },
            { validator: validatePassword }
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="Confirm PP"
          valuePropName="checked"
          rules={[{ validator: validateCheckbox }]}
        >
          <Checkbox className="confirm-checkbox-container">
            <FormattedMessage id="form.intake.policyLinkLong" />
          </Checkbox>
        </Form.Item>
      </Col>
      <Col span={24}>
        <TermsAndConditions />
      </Col>
      <Col span={12} className="text-center">
        <Button
          type="link"
          className="btn"
          onClick={() => dispatch(toggleSidebar())}
        >
          <FormattedMessage id="form.button.cancel" />
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={!canSubmit}
          block
        >
          <FormattedMessage id="form.control.continue" />
        </Button>
      </Col>
    </Row>
  );
};

export default Registration;
