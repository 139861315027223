import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Pagination, Row } from 'antd';

import { AppDispatch } from 'app/store';

import Filters from 'components/Filters/Filters';
import JobsList from 'components/Job/JobsList';
import LoadingIndicator from 'components/LoadingIndicator';
import PageTitle from 'components/PageTitle';

import { selectFilters } from 'features/filters/filtersSelectors';
import { setClearFilters } from 'features/filters/filtersSlice';
import {
  selectIsJobsLoading,
  selectJobs,
  selectJobsDetails
} from 'features/jobs/jobsSelectors';
import { fetchJobsData } from 'features/jobs/jobsThunks';

import { useInitializeFiltersFromURL } from 'hooks/useInitializeFiltersFromURL';
import { usePagination } from 'hooks/usePagination';

import styles from './Jobs.module.scss';

const BrowseJobsPage = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const filters = useSelector(selectFilters);
  const jobs = useSelector(selectJobs);
  const isJobsLoading = useSelector(selectIsJobsLoading);
  const { count } = useSelector(selectJobsDetails);
  const { currentPage, handlePageChange, pageSize } = usePagination();

  useInitializeFiltersFromURL();

  useEffect(() => {
    dispatch(fetchJobsData());
  }, [dispatch, filters]);

  return (
    <>
      <PageTitle>
        <h2 className={`title-custom ${styles.JobsTitle}`}>
          <FormattedMessage id="jobs_list.browse_text" />
        </h2>
      </PageTitle>
      <section className={styles.JobsList} id="jobs-list">
        <div className={`${styles.JobsListContainer} container`}>
          <Filters />
          {isJobsLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Row gutter={[16, 16]}>
                <JobsList jobs={jobs} />
              </Row>
              <div className={styles.pagination}>
                <Pagination
                  current={currentPage}
                  total={count}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  responsive
                />
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default BrowseJobsPage;
