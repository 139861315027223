import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from 'antd';
import { StatusCodes } from 'http-status-codes';
import AuthService from 'services/authService';

import LoadingOverlay from 'components/LoadingOverlay';

import { setCredentials } from 'features/auth/authSlice';
import {
  selectShowSidebar,
  toggleSidebar
} from 'features/sidebar/sidebarSlice';

import useErrorCatch from 'hooks/useErrorCatch';

import { post } from 'utils/api/api';

import { AuthSteps, ProviderType } from 'constants/enums';
import { INTAKE } from 'constants/pathNames';

import styles from './Authentication.module.scss';
import ChangePassword from './ChangePassword';
import CheckEmail from './CheckEmail';
import Email from './Email';
import Login from './Login';
import Registration from './Registration';

const Authentication = (): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();
  const jobId = params?.id || '';
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const showSidebar = useSelector(selectShowSidebar);
  const catchError = useErrorCatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authStep, setAuthStep] = useState<AuthSteps>(AuthSteps.CheckEmail);
  const [canSubmit, setCanSubmit] = useState(false);
  const [secondTitle, setSecondTitle] = useState('');
  const [title, setTitle] = useState('auth.label.userLogin');
  const [userName, setUserName] = useState('');

  const handleFieldsChange = (): void => {
    const fieldsValue = form.getFieldsValue();
    const fieldsError = form.getFieldsError();

    if (
      Object.keys(fieldsValue).every((field) => fieldsValue[field]) &&
      !fieldsError.some((field) => field.errors.length)
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const handleCheckEmail = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const { email } = form.getFieldsValue(['email']);

      const { exists, provider } = await AuthService.checkEmail({ email });

      if (exists) {
        switch (provider) {
          case ProviderType.Local:
            setAuthStep(AuthSteps.Login);
            break;

          case ProviderType.No:
            setAuthStep(AuthSteps.ForgotPassword);
            break;

          default:
            setAuthStep(AuthSteps.Registration);
            break;
        }
      } else {
        setAuthStep(AuthSteps.Registration);
      }

      setCanSubmit(false);
    } catch (error) {
      catchError({
        error,
        default: {
          message: 'Operation Failed',
          description: intl.formatMessage({ id: 'auth.error.checkEmail' })
        }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (): Promise<void> => {
    setIsLoading(true);

    const { email, password } = form.getFieldsValue(['email', 'password']);

    try {
      setIsLoading(true);
      setCanSubmit(false);

      const { accessToken } = await AuthService.login({ email, password });

      dispatch(
        setCredentials({
          token: accessToken
        })
      );

      dispatch(toggleSidebar());
    } catch (error) {
      catchError({
        error,
        messages: {
          // On wrong credentials BE returns 400
          [StatusCodes.BAD_REQUEST]: {
            message: intl.formatMessage({ id: 'auth.error.wrongCredentials' })
          },
          [StatusCodes.UNAUTHORIZED]: {
            message: intl.formatMessage({ id: 'auth.error.wrongCredentials' })
          }
        }
      });

      setCanSubmit(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = (): void => {
    setAuthStep(AuthSteps.ForgotPassword);
  };

  const handleChangePassword = (): void => {
    setIsLoading(true);

    const { email, password, verification } = form.getFieldsValue([
      'email',
      'password',
      'verification'
    ]);

    post('/auth/password', { email, password, verification })
      .then(() => {
        handleLogin();
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRegistration = (): void => {
    setIsLoading(true);

    const { email, password, verification } = form.getFieldsValue([
      'email',
      'password',
      'verification'
    ]);

    post('/auth/signup', { email, password, verification })
      .then(() => {
        handleLogin().then(() => navigate(INTAKE, { state: { jobId } }));
      })
      .catch(() => {
        form.setFields([
          {
            name: 'verification',
            errors: [
              intl.formatMessage({ id: 'auth.validation.incorrectCode' })
            ]
          }
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    switch (authStep) {
      case AuthSteps.Registration: {
        setSecondTitle('auth.label.welcomeToJoblio');
        setTitle('auth.label.createAccount');
        break;
      }
      case AuthSteps.CheckEmail: {
        setSecondTitle('');
        setTitle('auth.label.userLogin');
        break;
      }
      case AuthSteps.Login: {
        setSecondTitle('');
        setTitle('auth.label.userLogin');
        break;
      }
      case AuthSteps.ForgotPassword: {
        setSecondTitle('auth.label.passwordRecovery');
        break;
      }
      default: {
        setSecondTitle('');
        setTitle('auth.label.userLogin');
        break;
      }
    }
  }, [authStep]);

  useEffect(
    () => (): void => {
      form.resetFields();
      setAuthStep(AuthSteps.CheckEmail);
    },
    [showSidebar]
  );

  const renderContent = (step: AuthSteps): ReactElement => {
    switch (step) {
      case AuthSteps.Login:
        return (
          <Login
            handleSubmit={handleLogin}
            handleForgotPassword={handleForgotPassword}
            canSubmit={canSubmit}
          />
        );
      case AuthSteps.Registration:
        return (
          <Registration
            form={form}
            handleSubmit={handleRegistration}
            canSubmit={canSubmit}
          />
        );
      case AuthSteps.ForgotPassword:
        return (
          <ChangePassword
            form={form}
            handleSubmit={handleChangePassword}
            canSubmit={canSubmit}
          />
        );
      case AuthSteps.CheckEmail:
      default:
        return (
          <CheckEmail handleSubmit={handleCheckEmail} canSubmit={canSubmit} />
        );
    }
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <h2 className={styles.title}>
        <FormattedMessage id={title} />
      </h2>
      {secondTitle && (
        <p className={styles.secondTitle}>
          <FormattedMessage id={secondTitle} />
        </p>
      )}
      {userName && authStep === AuthSteps.Login && (
        <p className={styles.secondTitle}>
          <FormattedMessage id="auth.label.welcomeBack" values={{ userName }} />
        </p>
      )}
      <Form
        form={form}
        className="form-container"
        requiredMark={false}
        autoComplete="off"
        onFieldsChange={handleFieldsChange}
      >
        <Email authStep={authStep} />
        {renderContent(authStep)}
      </Form>
    </>
  );
};

export default Authentication;
