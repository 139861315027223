import store from 'app/store';

import { selectAuthToken } from 'features/auth/authSlice';

import checkStatus from 'utils/api/checkStatus';
import errorParser from 'utils/api/errorParser';
import responseParser from 'utils/api/responseParser';

import { HOST, OPEN_API } from 'constants/api';

interface APIConfig {
  method: 'GET' | 'PUT' | 'POST';
  headers?: HeadersInit;
  body?: string | FormData;
}

const fetchApi = (
  path: string,
  config: APIConfig,
  isOpenApi?: boolean
): Promise<any> => {
  const token = selectAuthToken(store.getState());
  const headers: HeadersInit = {
    ...(!(config.body instanceof FormData)
      ? { 'Content-Type': 'application/json' }
      : {}),
    ...(token ? { Authorization: token } : {}),
    ...config.headers
  };

  const fullConfig: RequestInit = {
    ...config,
    headers: { ...headers, ...config.headers }
  };

  return fetch(`${isOpenApi ? OPEN_API : HOST}${path}`, fullConfig)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export const get = (
  path: string,
  headers?: HeadersInit,
  isOpenApi?: boolean
): Promise<any> => fetchApi(path, { method: 'GET', headers }, isOpenApi);

export const put = (
  path: string,
  body: object,
  headers?: HeadersInit
): Promise<any> =>
  fetchApi(path, { method: 'PUT', body: JSON.stringify(body), headers });

export const post = (
  path: string,
  body: object,
  headers?: HeadersInit
): Promise<any> =>
  fetchApi(path, { method: 'POST', body: JSON.stringify(body), headers });

export const postFormData = (
  path: string,
  body: FormData,
  headers?: HeadersInit
): Promise<any> => fetchApi(path, { method: 'POST', body, headers });

export default { get, post };
