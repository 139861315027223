import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import getLabelByValue from 'utils/getLabelByValue';

import { Dictionaries } from 'constants/enums';

import styles from './JobDetailsElement.module.scss';

interface JobDetailsElementProps {
  value: string | ReactElement;
  label: string;
  dict?: Dictionaries;
}

const JobDetailsElement: FC<JobDetailsElementProps> = ({
  value,
  dict,
  label
}) => {
  const text =
    dict && typeof value === 'string'
      ? getLabelByValue(useSelector(selectDictionary(dict)), value) || '—'
      : value;

  return (
    <div className={styles.container}>
      {text}
      <div className={styles.label}>
        <FormattedMessage id={label} />
      </div>
    </div>
  );
};

export default JobDetailsElement;
