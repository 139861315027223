import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import cs from 'classnames';

import LocationDisplay from 'components/LocationDisplay/LocationDisplay';

import { Location } from 'types/commons';

import styles from './JobLocation.module.scss';

interface JobLocationProps {
  location: Location;
  className?: string;
}

const JobLocation: FC<JobLocationProps> = ({ location, className }) => {
  return (
    <div className={cs(styles.container, className)}>
      <LocationDisplay location={location} />
      <p>
        <FormattedMessage id="job.label.location" />
      </p>
    </div>
  );
};

export default JobLocation;
