import LOCALES from 'constants/locales';

import ENGLISH from './translations/join-strings-en.json';

interface LangObject {
  [key: string]: any;
}

interface LocaleMessage {
  messages: LangObject;
  locale: string;
}

/**
 * This function is a wrapper for the react-intl provider
 * @param messages Inject with translations
 * @param locale
 * @returns {{messages, locale}}
 */
const langSelector = (messages: LangObject, locale: string): LocaleMessage => ({
  messages: { ...messages },
  locale
});

export const enLang = langSelector(ENGLISH, LOCALES.ENGLISH);

const languages = [{ lang: ENGLISH, locale: LOCALES.ENGLISH }];

const languagesObj = languages.reduce((acc, curr) => {
  const { lang, locale } = curr;
  const langSelectorResult = langSelector(lang, locale);
  return { ...acc, [`${locale}Lang`]: langSelectorResult };
}, {});

export default languagesObj;
