import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setFilters } from 'features/filters/filtersSlice';

import { Filters } from 'types/filters';

export const useInitializeFiltersFromURL = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filters: Partial<Filters> = {};

    params.forEach((value, key) => {
      if (value) {
        if (key === 'industry') {
          filters[key] = value.includes(',') ? value.split(',') : [value];
        } else {
          // @ts-ignore
          filters[key] = value.includes(',') ? value.split(',') : value;
        }
      }
    });

    dispatch(setFilters(filters));
  }, [dispatch]);
};
