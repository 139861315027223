import { FC, ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Affix, Form, FormInstance, Progress } from 'antd';
import cs from 'classnames';

import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';

import styles from './QuestionnaireProgressBar.module.scss';

interface QuestionnaireProgressBarProps {
  form: FormInstance;
}

const QuestionnaireProgressBar: FC<QuestionnaireProgressBarProps> = ({
  form
}): ReactElement => {
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [isFixBar, setIsFixBar] = useState<boolean | undefined>(false);
  const fieldsValues = Form.useWatch([], form);

  const handleAffix = (isAffix: boolean | undefined): void => {
    setIsFixBar(isAffix);
  };

  useEffect(() => {
    if (fieldsValues) {
      const totalFields = Object.keys(fieldsValues).length;

      const completedFields = Object.entries(fieldsValues).reduce(
        (count, [fieldName, fieldValue]) => {
          const isFilled =
            fieldValue !== null &&
            fieldValue !== undefined &&
            !(Array.isArray(fieldValue) && fieldValue.length === 0) &&
            fieldValue !== '';

          return isFilled ? count + 1 : count;
        },
        0
      );

      const percent = Math.round((completedFields / totalFields) * 100);
      setPercentCompleted(percent);
    }
  }, [fieldsValues, form]);

  return (
    <Affix offsetTop={0} onChange={handleAffix}>
      <div
        className={cs(styles.container, { [styles.shortContainer]: isFixBar })}
      >
        <Progress percent={percentCompleted} showInfo={false} />
        <div className={styles.progressText}>
          {percentCompleted}%{' '}
          <FormattedMessage id="screening.progressBar.completed" />
        </div>
        <div className={styles.flashText}>
          <FlashIcon />
          <FormattedMessage id="screening.progressBar.flashText" />
        </div>
      </div>
    </Affix>
  );
};

export default QuestionnaireProgressBar;
