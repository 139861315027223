import { get } from 'utils/api/api';

import { Job, JobList } from 'types/jobs';

import { JOB, JOBS } from 'constants/endpoints';

const JobsService = {
  getJobs: async (queryString: string): Promise<JobList> => {
    const res = await get(`${JOBS}${queryString}`);
    return res;
  },

  getJobById: async (jobId: string): Promise<Job> => {
    const res = await get(`${JOB}/${jobId}`);
    return res;
  }
};

export default JobsService;
