import formatAndOrderData from 'utils/formatAndOrderData';

import { Country, CountryOption } from 'types/commons';

import countriesData, { COUNTRIES_ORDER } from 'constants/countriesData';

const getCountriesOptions = (
  countries?: Country[] | null,
  useNameAsValue: boolean = false
): CountryOption[] => {
  const baseOptions = countries
    ? countries.map(({ code, name }) => ({
        value: useNameAsValue ? name : code,
        label: name
      }))
    : countriesData.map(({ code, name }) => ({
        value: useNameAsValue ? name : code,
        label: name
      }));

  return formatAndOrderData(baseOptions, COUNTRIES_ORDER) as CountryOption[];
};

export default getCountriesOptions;
