import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, notification } from 'antd';
import cs from 'classnames';
import ApplicationService from 'services/applicationService';
import JobsService from 'services/jobsService';

import JobDetailsHeader from 'components/JobDetailsHeader/JobDetailsHeader';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { selectIsLoggedIn, selectUserId } from 'features/auth/authSlice';
import { toggleSidebar } from 'features/sidebar/sidebarSlice';

import formatMarkdown from 'utils/formatting/formatMarkdown';

import { ErrorResponse } from 'types/commons';
import { Job } from 'types/jobs';

import { INTAKE } from 'constants/pathNames';

import styles from './JobDetails.module.scss';

const JobDetails = (): ReactElement | null => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: jobId } = useParams<{ id: string }>();
  const jobseekerId = useSelector(selectUserId);
  const isAnonymous = !useSelector(selectIsLoggedIn);

  const [jobData, setJobData] = useState<Job | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isApplied, setIsApplied] = useState(false);

  const buttonLabel = isApplied ? 'job.applied' : 'job.apply';

  if (!jobId) return null;

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const job = await JobsService.getJobById(jobId);
        setJobData(job);
      } catch {
        notification.error({
          message: 'Error loading job details',
          placement: 'topRight',
          duration: 5
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchJob();
  }, [jobId]);

  const handleApplyOnJob = async (): Promise<void> => {
    setIsLoading(true);

    try {
      if (isAnonymous) {
        dispatch(toggleSidebar());
        return;
      }

      const isIntakePassed =
        await ApplicationService.postIntakePassedCheck(jobseekerId);

      if (!isIntakePassed) {
        navigate(INTAKE, { state: { jobId } });
        return;
      }

      const applicationResponse =
        await ApplicationService.postCreateApplication({ jobseekerId, jobId });

      notification.success({
        message: "You've successfully applied for a job",
        placement: 'topRight',
        duration: 5
      });

      navigate(
        `/questionnaire/${jobId}/${jobseekerId}/${applicationResponse.id}`,
        { replace: true }
      );
    } catch (error: ErrorResponse | any) {
      if (
        error?.status === 400 &&
        error?.data?.message === 'Application already exists'
      ) {
        notification.success({
          message: 'You already applied for this job!',
          placement: 'topRight',
          duration: 5
        });
        setIsApplied(true);
      } else {
        notification.error({
          message: 'Error during application, please try again later',
          placement: 'topRight',
          duration: 5
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingIndicator />;
  if (!jobData) return null;

  const { title: jobTitle, description: jobDescription } = jobData;

  return (
    <div className={styles.jobDetails}>
      <JobDetailsHeader
        job={jobData}
        isLoading={isLoading}
        onApply={handleApplyOnJob}
        isUserApplied={isApplied}
      />
      <section className={styles.jobDetailsDescription}>
        <div className={`${styles.jobDetailsContainer} container`}>
          <section className={styles.description}>
            {jobDescription && (
              <div
                className={styles.jobDetailsText}
                dangerouslySetInnerHTML={{
                  __html: formatMarkdown(jobDescription)
                }}
              />
            )}
          </section>
          <section className={styles.applyJobContainer}>
            <p>
              <FormattedMessage
                id="job.label.jobDetails.apply"
                values={{ jobTitle }}
              />
            </p>
            <Button
              block
              type="primary"
              className={cs('btn', styles.buttonHolder)}
              disabled={isLoading || isApplied}
              onClick={handleApplyOnJob}
            >
              <FormattedMessage id={buttonLabel} />
            </Button>
          </section>
        </div>
      </section>
    </div>
  );
};

export default JobDetails;
