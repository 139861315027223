import ReactFbq from 'react-fbq';

import { GA_ID, GTM_ID } from 'config/gtmConfig';

import { getGDPRSettings } from './localStorage';

interface CustomWindow extends Window {
  [key: string]: any;
}

const customWindow: CustomWindow = window;
const isLocalhost = window.location.hostname === 'localhost';
const isTestURL = window.location.href.includes('testjoin');
const blockAnalytics = isLocalhost || isTestURL;

const initializeFacebookAnalytics = (): void => {
  const headScriptFacebook = document.createElement('script');

  headScriptFacebook.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1808257326217611');
  fbq('track', 'PageView');
  `;

  document.head.append(headScriptFacebook);
};

const addScript = (src: string, innerHTML?: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.async = true;
  if (src) {
    script.src = src;
  }
  if (innerHTML) {
    script.innerHTML = innerHTML;
  }
  document.head.appendChild(script);
  return script;
};

const initializeGA = (): void => {
  const script = addScript(
    `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`
  );

  script.onload = (): void => {
    customWindow.dataLayer = customWindow.dataLayer || [];
    const gtag = (...args: any[]): void => {
      customWindow.dataLayer.push(...args);
    };
    gtag('js', new Date());
    gtag('config', GA_ID);
  };
};

const initializeGTM = (): void => {
  addScript(
    '',
    `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');
  `
  );

  // Create noscript
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.insertBefore(noscript, document.body.firstChild);
};

const initializeClarity = (): void => {
  const clarity = 'clarity';
  customWindow[clarity] =
    customWindow[clarity] ||
    function clarityFunction(...args: any[]): void {
      (customWindow[clarity].q = customWindow[clarity].q || []).push(...args);
    };

  addScript('https://www.clarity.ms/tag/frzn04ae5h');
};

export const setupAnalytics = (): void => {
  const gdprSettings = getGDPRSettings();
  if (blockAnalytics) return;

  if (gdprSettings.A) {
  }
  if (gdprSettings.F) {
    initializeGTM();
    initializeGA();
    initializeClarity();
  }
  if (gdprSettings.T) {
    initializeFacebookAnalytics();
  }
};

/*
 * @description - This function is used to log events to Facebook Pixel
 * @param {string} eventName
 * @param {object} eventData - event data object / optional
 */

export const fbAnalytics = (
  eventName: string,
  eventData?: {
    jobCountry?: string;
    jobLocation?: string;
    jobTitle?: string;
    jobDepartment?: string;
  }
): void => {
  // @ts-ignore
  if (!import.meta.env.PROD) return;
  if (!eventName) return;
  if (window.location.href.includes('testjoin')) return;
  if (!ReactFbq) return;

  if (eventData) {
    ReactFbq.track({ title: eventName, data: eventData });
  } else {
    ReactFbq.track({ title: eventName });
  }
};
