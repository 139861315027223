import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';

import { fetchDictionary } from 'features/dictionaries/dictionariesThunks';

import useErrorCatch from 'hooks/useErrorCatch';

import { Dictionaries } from 'constants/enums';

const useLoadDictionaries = () => {
  const dispatch: AppDispatch = useDispatch();
  const catchError = useErrorCatch();

  const loadDictionaries = useCallback(async () => {
    const dictionaries: Array<Dictionaries> = [
      Dictionaries.Industry,
      Dictionaries.Skillset,
      Dictionaries.Language,
      Dictionaries.LanguageLevel,
      Dictionaries.PayrollRates,
      Dictionaries.JobType,
      Dictionaries.JobShift
    ];

    try {
      await Promise.all(
        dictionaries.map((dictionary) =>
          dispatch(fetchDictionary(dictionary)).unwrap()
        )
      );
    } catch (error) {
      catchError({
        error,
        default: {
          message: 'Error fetching dictionaries, please try to reload the page!'
        }
      });
    }
  }, [dispatch, catchError]);

  useEffect(() => {
    loadDictionaries();
  }, []);
};

export default useLoadDictionaries;
