import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { FormInstance, Input, Select } from 'antd';
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumberFromString
} from 'libphonenumber-js';
import debounce from 'lodash/debounce';

import CountryPreview from 'components/CountryPreview';

import useCountryList from 'hooks/useCountryList';
import useInitialPhoneNumber from 'hooks/useInitialPhoneNumber';
import useWindowDimensions from 'hooks/useWindowDimensions';

import styles from './PhoneSelect.module.scss';

const { Option } = Select;

interface PhoneSelectProps {
  form: FormInstance;
  name: string;
}

const PhoneSelect: FC<PhoneSelectProps> = ({ form, name }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowDimensions();
  const countryList = useCountryList();

  const {
    selectedCountry,
    phoneCode,
    phoneNumber,
    isValid,
    setSelectedCountry,
    setPhoneCode,
    setPhoneNumber,
    setIsValid
  } = useInitialPhoneNumber(form, name, countryList);

  const [parentWidth, setParentWidth] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      setParentWidth(containerRef.current.offsetWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (phoneNumber) {
      form.setFieldsValue({ [name]: `+${phoneCode}${phoneNumber}` });
      form.setFields([
        { name, errors: isValid ? [] : ['Invalid phone number'] }
      ]);
    }
  }, [phoneCode, phoneNumber, form, name, isValid]);

  const validatePhoneNumber = useCallback(
    (number: string, country: { callingCode?: string; code?: string }) => {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        `+${country.callingCode ?? ''}${number}`,
        country.code as CountryCode
      );
      return parsedPhoneNumber
        ? isValidPhoneNumber(parsedPhoneNumber.number)
        : false;
    },
    []
  );

  const debouncedValidation = useCallback(
    debounce(
      (number: string, country: { callingCode?: string; code?: string }) => {
        setIsValid(validatePhoneNumber(number, country));
      },
      300
    ),
    [setIsValid, validatePhoneNumber]
  );

  const handleCountryChange = useCallback(
    (value: string): void => {
      const country = countryList.find((c) => c.value === value);
      if (country) {
        setSelectedCountry(country);
        setPhoneCode(country.callingCode);
      }
    },
    [countryList, setSelectedCountry, setPhoneCode]
  );

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const input = e.target.value.replace(/\s/g, '');
      const withoutCountryCode = input.replace(/^\+\d+/, '');
      setPhoneNumber(withoutCountryCode);
      debouncedValidation(withoutCountryCode, selectedCountry || {});
    },
    [debouncedValidation, selectedCountry, setPhoneNumber]
  );

  const columnDataChildren = useMemo(
    () =>
      countryList.map((country) => (
        <Option
          key={country.code}
          value={country.value}
          label={<CountryPreview countryCode={country.code} iconOnly />}
        >
          <CountryPreview
            countryCode={country.code}
            countryName={country.label}
          />{' '}
          (+{country.callingCode})
        </Option>
      )),
    [countryList]
  );

  return (
    <div className={styles.container} ref={containerRef}>
      <Input
        addonBefore={
          <Select
            value={selectedCountry?.value || undefined}
            onChange={handleCountryChange}
            optionLabelProp="label"
            popupClassName={styles.dropdownContainer}
            popupMatchSelectWidth={parentWidth}
          >
            {columnDataChildren}
          </Select>
        }
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        prefix={phoneCode ? `+${phoneCode}` : ''}
      />
    </div>
  );
};

export default PhoneSelect;
