import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Spin } from 'antd';

import styles from './LoadingIndicator.module.scss';

export default (): ReactElement => {
  const intl = useIntl();
  const loadingText = `${intl.formatMessage({ id: 'utils.loading' })}...`;

  return (
    <Spin size="large" className={styles.LoadingIndicator}>
      {loadingText}
    </Spin>
  );
};
