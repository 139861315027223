import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Checkbox } from 'antd';

import { setupAnalytics } from 'utils/analytics';
import { saveGDPRSettings } from 'utils/localStorage';

import { GDPROptions } from 'constants/common';

import { ReactComponent as Arrow } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './CustomizePrivacySettings.module.scss';

interface CustomizePrivacySettingsProps {
  toggleCustomizeSettings: () => void;
  setVisible: (value: boolean) => void;
  setGDPR: Dispatch<SetStateAction<boolean>>;
}

const CustomizePrivacySettings = ({
  toggleCustomizeSettings,
  setVisible,
  setGDPR
}: CustomizePrivacySettingsProps): ReactElement => {
  const [checkedOptions, setCheckedOptions] = useState<any>([
    GDPROptions.ANALYTICS,
    GDPROptions.FUNCTIONAL_COOKIES,
    GDPROptions.TARGETING_COOKIES
  ]);
  const checkIsSettingSet = (setting: string): boolean =>
    checkedOptions.indexOf(setting) > -1;

  const OptionRequiredAnalytics = (
    <div className={styles.OptionHolder}>
      <h3
        className={
          checkIsSettingSet(GDPROptions.ANALYTICS)
            ? styles.highlightedOption
            : ''
        }
      >
        <FormattedMessage id="GDPR.essential_cookies" />
      </h3>
      <p>
        <FormattedMessage id="GDPR.required_analytics_annotation" />
      </p>
    </div>
  );
  const OptionFunctionalCookies = (
    <div className={styles.OptionHolder}>
      <h3
        className={
          checkIsSettingSet(GDPROptions.FUNCTIONAL_COOKIES)
            ? styles.highlightedOption
            : ''
        }
      >
        <FormattedMessage id="GDPR.functional_cookies_title" />
      </h3>
      <p>
        <FormattedMessage id="GDPR.functional_cookies_text" />
      </p>
    </div>
  );
  const OptionTargetingCookies = (
    <div className={styles.OptionHolder}>
      <h3
        className={
          checkIsSettingSet(GDPROptions.TARGETING_COOKIES)
            ? styles.highlightedOption
            : ''
        }
      >
        <FormattedMessage id="GDPR.targeting_cookies_title" />
      </h3>
      <p>
        <FormattedMessage id="GDPR.targeting_cookies_text" />
      </p>
    </div>
  );

  const onAccept = (): void => {
    const settings: any = {
      [GDPROptions.ANALYTICS]: checkIsSettingSet(GDPROptions.ANALYTICS),
      [GDPROptions.FUNCTIONAL_COOKIES]: checkIsSettingSet(
        GDPROptions.FUNCTIONAL_COOKIES
      ),
      [GDPROptions.TARGETING_COOKIES]: checkIsSettingSet(
        GDPROptions.TARGETING_COOKIES
      )
    };

    saveGDPRSettings(settings);
    setGDPR(settings);
    setVisible(false);
    setupAnalytics();
  };

  const onDecline = (): void => setVisible(false);

  return (
    <div className={styles.Container}>
      <div className={styles.ContainerInner}>
        <h3 className={styles.Header}>
          <FormattedMessage id="GDPR.customize_privacy_settings" />
          <Button
            type="link"
            className={`${styles.NoButton} link-btn`}
            onClick={onDecline}
          >
            <CloseIcon />
          </Button>
        </h3>
        <p className={styles.MainText}>
          <FormattedMessage id="GDPR.to_give_you_personalized" />
        </p>
        <Checkbox.Group
          className={styles.optionsContainer}
          options={[
            {
              label: OptionRequiredAnalytics,
              value: GDPROptions.ANALYTICS,
              disabled: true
            },
            {
              label: OptionFunctionalCookies,
              value: GDPROptions.FUNCTIONAL_COOKIES
            },
            {
              label: OptionTargetingCookies,
              value: GDPROptions.TARGETING_COOKIES
            }
          ]}
          value={checkedOptions}
          onChange={(checkedValue): void => setCheckedOptions(checkedValue)}
        />
        <div className={styles.ContainerButton}>
          <Button
            type="link"
            onClick={toggleCustomizeSettings}
            className={styles.BackButton}
          >
            <Arrow />
            <span>
              <FormattedMessage id="GDPR.back" />
            </span>
          </Button>
          <Button
            type="primary"
            className={`${styles.AcceptButton} btn btn-small`}
            onClick={onAccept}
          >
            <FormattedMessage id="GDPR.accept" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomizePrivacySettings;
