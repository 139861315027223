import { Location } from 'types/commons';

const parseCity = (fullCity: string): Location => {
  const parts = fullCity.split(',').map((part) => part.trim());

  const [city, stateOrCountry, countryOrNull] = parts;

  // If 2 parts - city and country, if 3 - city, state, country
  const hasState = parts.length === 3;

  return {
    city: city || '',
    state: hasState ? stateOrCountry : '',
    country: hasState ? countryOrNull : stateOrCountry || ''
  };
};

export default parseCity;
