import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { DefaultOptionType } from 'antd/es/select';

import CountryPreview from 'components/CountryPreview';
import SkillsetSelect from 'components/FormCommon/SkillsetSelect';
import MultiSelectCustom from 'components/MultiSelectCustom';
import PhoneSelect from 'components/PhoneSelect/PhoneSelect';
import SelectLanguageLevels from 'components/SelectLanguageLevels/SelectLanguageLevels';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import useCountryCitySelect from 'hooks/useCountryCitySelect';
import { useGetResource } from 'hooks/useGetResource';

import getCountriesOptions from 'utils/getCountriesOptions';
import parseCity from 'utils/parseCity';

import { Country, CountryOption } from 'types/commons';
import { Jobseeker } from 'types/jobseekers';

import { PRIVACY_PATH } from 'constants/common';
import { COUNTRIES } from 'constants/endpoints';
import { Dictionaries } from 'constants/enums';
import { decisionBooleanArray } from 'constants/formData';

interface IntakeFormProps {
  form: FormInstance;
  loading: boolean;
  onFormSubmit: (fields: Partial<Jobseeker>) => Promise<void>;
  initialValues?: Record<string, any>;
}

const IntakeForm = ({
  form,
  loading,
  onFormSubmit,
  initialValues = {}
}: IntakeFormProps): ReactElement => {
  const intl = useIntl();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState<boolean>(false);
  const fieldsValues = Form.useWatch([], form);

  const defaultValidateMessages = {
    required: 'Field is required'
  };

  const { data: countries, isLoading: isCountriesLoading } =
    useGetResource<Country>({ url: COUNTRIES, isOpenApi: true });

  const { countrySelect, citySelect, rawString } = useCountryCitySelect({
    form,
    countries,
    isCountriesLoading,
    isCountryRequired: true,
    isCityRequired: true
  });

  const countriesOptions = useMemo(
    () => getCountriesOptions(countries, true),
    [countries]
  );

  const industriesDict = useSelector(selectDictionary(Dictionaries.Industry));
  const jobShiftDict = useSelector(selectDictionary(Dictionaries.JobShift));

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setIsFormValid(true),
      () => setIsFormValid(false)
    );
  }, [fieldsValues]);

  useEffect(() => {
    if (initialValues.email) {
      form.setFieldsValue({ email: initialValues.email });
    }
  }, [initialValues.email, form]);

  const handleSubmitForm = () => {
    const location = parseCity(rawString);
    const fields = {
      ...fieldsValues,
      location
    };

    onFormSubmit(fields);
  };

  const onChangePrivacyCheckbox = (e: CheckboxChangeEvent): void => {
    setIsPrivacyChecked(e.target.checked);
  };

  const isFieldDisabled = (fieldName: string): boolean =>
    initialValues && initialValues[fieldName] !== undefined;

  const privacyPolicyLink = (
    <a
      href={PRIVACY_PATH}
      target="_blank"
      rel="noreferrer"
      aria-label={intl.formatMessage({ id: 'form.intake.policyLink' })}
    >
      <FormattedMessage id="form.intake.policyLink" />
    </a>
  );

  const renderCountryOption = ({
    data
  }: {
    data: CountryOption;
  }): ReactElement => (
    <CountryPreview
      // countryCode={data.value}
      countryName={data.label}
    />
  );

  const renderCountryLabel = (option: DefaultOptionType): ReactNode => (
    <CountryPreview
      countryName={String(option.label)}
      // countryCode={String(option.value)}
    />
  );

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      validateMessages={defaultValidateMessages}
      initialValues={{ ...initialValues }}
    >
      <Row gutter={[24, 16]}>
        <Col span={24} md={12}>
          <Form.Item
            name="firstName"
            label={intl.formatMessage({ id: 'form.intake.firstName' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.firstName'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="lastName"
            label={intl.formatMessage({ id: 'form.intake.lastName' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.lastName'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          {countrySelect}
        </Col>
        <Col span={24} md={12}>
          {citySelect}
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="mailingAddress"
            label={intl.formatMessage({ id: 'form.intake.mailAddress' })}
            tooltip={{
              title: intl.formatMessage({ id: 'form.intake.mailAddress.info' }),
              icon: <InfoCircleOutlined />
            }}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.mailAddress.placeholder'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="citizenship"
            label={intl.formatMessage({ id: 'form.intake.citizenshipNew' })}
            rules={[{ required: true }]}
          >
            <Select
              options={countriesOptions}
              optionRender={renderCountryOption}
              labelRender={renderCountryLabel}
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.citizenshipNew'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="phoneNumber"
            label={intl.formatMessage({ id: 'form.intake.phone' })}
            rules={[{ required: true }]}
          >
            <PhoneSelect name="phoneNumber" form={form} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="email"
            label={intl.formatMessage({ id: 'form.intake.email' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.email'
              })}
              disabled={isFieldDisabled('email')}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <SelectLanguageLevels
            form={form}
            name="languages"
            label={intl.formatMessage({ id: 'form.intake.languages' })}
            placeholder={intl.formatMessage({
              id: 'form.intake.placeholder.languages'
            })}
          />
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="skills"
            label={intl.formatMessage({ id: 'form.intake.professionNew' })}
            rules={[{ required: true }]}
          >
            <SkillsetSelect
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.professionNew'
              })}
              title=""
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} className="offset-right">
          <Form.Item
            name="lookingForJobInAreas"
            label={intl.formatMessage({ id: 'form.intake.occupationNew' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="lookingForJobInAreas"
              labelId="form.intake.occupationNew"
              form={form}
              options={industriesDict}
              placeholderId="form.intake.placeholder.occupationNew"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="weekendOvertime"
            label={intl.formatMessage({
              id: 'screening.form.label.areYouOpenToWork'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={decisionBooleanArray}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="preferredSchedule"
            label={intl.formatMessage({
              id: 'screening.form.label.selectShift'
            })}
            rules={[{ required: true }]}
          >
            <Checkbox.Group options={jobShiftDict} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="felonyConviction"
            label={intl.formatMessage({
              id: 'screening.form.label.felonyConviction'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={decisionBooleanArray}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="backgroundCheckConsent"
            label={intl.formatMessage({
              id: 'screening.form.label.backgroundCheck'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={decisionBooleanArray}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item className="max-height-group" rules={[{ required: true }]}>
            <Checkbox onChange={onChangePrivacyCheckbox}>
              <FormattedMessage
                id="form.intake.policy"
                values={{ link: privacyPolicyLink }}
              />
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className="intake-submit-container">
        <Button
          type="primary"
          loading={loading}
          className="btn btn-primary intake-button"
          htmlType="submit"
          onClick={handleSubmitForm}
          disabled={!isFormValid || !isPrivacyChecked}
        >
          <FormattedMessage id="form.intake.submit" />
        </Button>
      </div>
    </Form>
  );
};

export default IntakeForm;
