import { ReactElement } from 'react';

import styles from './PageTitle.module.scss';

const PageTitle = ({
  children
}: {
  children: ReactElement | ReactElement[] | any;
}): ReactElement => (
  <section className={styles.PageTitle}>
    <div className={`${styles.PageTitleContainer} container`}>{children}</div>
  </section>
);

export default PageTitle;
