import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, Input } from 'antd';

import { AuthSteps } from 'constants/enums';

import { ReactComponent as IconEmail } from 'assets/icons/email.svg';

import styles from '../Authentication.module.scss';

interface EmailProps {
  authStep: AuthSteps;
}

const Email: FC<EmailProps> = ({ authStep }) => {
  const intl = useIntl();
  const isLoginStep = authStep !== AuthSteps.CheckEmail;

  return (
    <Form.Item
      label={intl.formatMessage({ id: 'form.email' })}
      name="email"
      className={styles.emailContainer}
      rules={[
        {
          required: true,
          message: <FormattedMessage id="validation.email" />
        },
        {
          type: 'email',
          message: <FormattedMessage id="validation.email.valid" />
        }
      ]}
    >
      <Input suffix={<IconEmail className="icon" />} disabled={isLoginStep} />
    </Form.Item>
  );
};

export default Email;
