import { get } from 'utils/api/api';

import { CITIES } from 'constants/endpoints';

const CommonsService = {
  getCitiesByCountryCodeAndSpelling: async (
    countryCode: string,
    spelling: string
  ): Promise<string[]> => {
    const response = await get(
      `${CITIES}?countryCode=${countryCode}&search=${spelling}`,
      {},
      true
    );
    return response;
  }
};

export default CommonsService;
