import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'app/store';

import { updateURLWithFilters } from 'utils/updateURLWithFilters';

import { Filters, SortOrders } from 'types/filters';

import { FIRST_PAGE_INDEX, PAGE_SIZE } from 'constants/common';

import { selectFilters } from './filtersSelectors';

const initialState: Filters = {
  country: undefined,
  industry: undefined,
  page: null,
  pageSize: PAGE_SIZE,
  sortField: null,
  order: null
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setCountry(state, action: PayloadAction<string | undefined>) {
      state.country = action.payload;
      state.page = FIRST_PAGE_INDEX;
      updateURLWithFilters(state);
    },
    setIndustry(state, action: PayloadAction<string[]>) {
      state.industry = action.payload;
      state.page = FIRST_PAGE_INDEX;
      updateURLWithFilters(state);
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      updateURLWithFilters(state);
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      updateURLWithFilters(state);
    },
    setSortField(state, action: PayloadAction<string | null>) {
      state.sortField = action.payload;
      updateURLWithFilters(state);
    },
    setSortOrder(state, action: PayloadAction<SortOrders | null>) {
      state.order = action.payload;
      updateURLWithFilters(state);
    },
    setFilters(state, action: PayloadAction<Partial<Filters>>) {
      const newState = { ...state, ...action.payload };
      updateURLWithFilters(newState);
      return newState;
    },
    setClearFilters(state) {
      state.country = undefined;
      state.industry = undefined;
      updateURLWithFilters(state);
    },
    setClearFiltersAndPaging: () => {
      updateURLWithFilters(initialState);
      return initialState;
    }
  }
});

export const {
  setCountry,
  setIndustry,
  setPage,
  setPageSize,
  setSortField,
  setSortOrder,
  setFilters,
  setClearFilters,
  setClearFiltersAndPaging
} = filtersSlice.actions;

export const generateParams = (filters: Partial<Filters>): string => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      params.append(key, value.join(','));
    } else if (value !== undefined && value !== null) {
      params.append(key, value.toString());
    }
  });

  return params.toString() ? `?${params.toString()}` : '';
};

export const updateFilters =
  (filters: Partial<Filters>): AppThunk =>
  (dispatch, getState) => {
    const currentFilters = selectFilters(getState());
    dispatch(setFilters({ ...currentFilters, ...filters }));
  };

export default filtersSlice.reducer;
