import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Tag } from 'antd';

import { RootState } from 'app/store';

import CountryPreview from 'components/CountryPreview';

import { selectFilters } from 'features/filters/filtersSelectors';
import {
  setClearFilters,
  setCountry,
  setIndustry
} from 'features/filters/filtersSlice';

import { FormattedOption } from 'types/dictionaries';

import { ReactComponent as CloseIcon } from 'assets/icons/add.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import styles from './SelectedFiltersTags.module.scss';

type TagType = 'country' | 'industry';

interface SelectedFiltersTagsProps {
  industries: FormattedOption[];
}

const getIndustryLabel = (
  value: string,
  industries: FormattedOption[]
): string => {
  const industry = industries.find((ind) => ind.value === value);
  return typeof industry?.label === 'string' ? industry.label : value;
};

const getIndustryValue = (
  label: string,
  industries: FormattedOption[]
): string | undefined => {
  const industry = industries.find((ind) => ind.label === label);
  return industry?.value;
};

const SelectedFiltersTags: FC<SelectedFiltersTagsProps> = ({ industries }) => {
  const dispatch = useDispatch();
  const { country, industry } = useSelector((state: RootState) =>
    selectFilters(state)
  );

  const handleRemoveTag = useCallback(
    (type: TagType, value: string) => {
      if (type === 'country') {
        dispatch(setCountry(undefined));
      } else if (type === 'industry' && industry) {
        const industryValue = getIndustryValue(value, industries);
        if (industryValue) {
          dispatch(
            setIndustry(industry.filter((item) => item !== industryValue))
          );
        }
      }
    },
    [dispatch, industry, industries]
  );

  const handleClearAll = useCallback(() => {
    dispatch(setClearFilters());
  }, [dispatch]);

  const tags = useMemo(
    () => [
      ...(country
        ? [{ type: 'country', label: <CountryPreview countryCode={country} /> }]
        : []),
      ...(industry
        ? industry.map((ind) => ({
            type: 'industry',
            label: getIndustryLabel(ind, industries)
          }))
        : [])
    ],
    [country, industry, industries]
  );

  return (
    <div className={styles.selectedFiltersTags}>
      {tags.length > 0 && (
        <Button className={styles.clearButton} onClick={handleClearAll}>
          Clear All <TrashIcon />
        </Button>
      )}
      {tags.map((tag) => (
        <Tag
          key={`${tag.type}-${String(tag.label)}`}
          closable
          closeIcon={<CloseIcon />}
          onClose={() =>
            handleRemoveTag(tag.type as TagType, String(tag.label))
          }
          className={styles.tag}
        >
          {tag.label}
        </Tag>
      ))}
    </div>
  );
};

export default SelectedFiltersTags;
