import { FC } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';

import { useSortOptions } from 'hooks/useSortOptions';

import styles from './SortDropdown.module.scss';

const SortDropdown: FC = () => {
  const { items, handleMenuClick, currentLabel } = useSortOptions();

  return (
    <div className={styles.sortDropdownContainer}>
      <Dropdown
        menu={{ items, onClick: handleMenuClick }}
        trigger={['click']}
        overlayClassName={styles.sortDropdownInnerContainer}
      >
        <Button className={styles.buttonContainer}>
          {currentLabel} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default SortDropdown;
