import { FC } from 'react';

import { Tag as AntdTag } from 'antd';
import cs from 'classnames';

import styles from './Tag.module.scss';

interface TagProps {
  label: string;
  big?: boolean;
  className?: string;
}

const Tag: FC<TagProps> = ({ label, big = false, className }) => {
  const tagClasses = cs(styles.container, {
    [styles.bigSize]: big
  });

  return (
    <AntdTag className={cs(tagClasses, className)} bordered={false}>
      {label}
    </AntdTag>
  );
};

export default Tag;
