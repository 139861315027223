export const MAIN = '/';
export const NOT_FOUND = '/not-found';

export const JOBS = '/jobs';
export const JOB_DETAILS = '/jobs/:id';

export const INTAKE = '/intake';
export const INTAKE_THANK_YOU = '/intake/thank-you';

export const QUESTIONNAIRE = '/questionnaire/:jobId/:userId/:applicationId';
export const QUESTIONNAIRE_THANK_YOU = '/questionnaire/thank-you';

export const AMBASSADOR = '/ambassador';
export const AMBASSADOR_JOBS = '/jobs/ambassador';
export const AMBASSADOR_INTAKE = '/intake/ambassador/:id';
export const AMBASSADOR_DETAILS = '/jobs/ambassador/:id';
export const AMBASSADOR_THANK_YOU = '/intake/ambassador/thank-you';

export const ADMIN = '/admin';
export const CHAT = '/admin/chat';
