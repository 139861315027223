import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UnknownAction } from '@reduxjs/toolkit';

import { scrollToTop } from 'utils/scrollToTop';

type ActionCreator<T> = (value: T) => UnknownAction;

export const useHandleChange = <T>() => {
  const dispatch = useDispatch();

  return useCallback(
    (actionCreator: ActionCreator<T>) => (value: T) => {
      dispatch(actionCreator(value));
      scrollToTop();
    },
    [dispatch]
  );
};

export default useHandleChange;
