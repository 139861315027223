import getSingleLabelFromDictionary from 'utils/getSingleLabelFromDictionary';

import { Application } from 'types/application';
import { FormattedOption } from 'types/dictionaries';
import { Job } from 'types/jobs';
import { FormattedPDF } from 'types/pdf';

import { Dictionaries } from 'constants/enums';

const formatPDFData = (
  application: Partial<Application>,
  job: Partial<Job>,
  dictionaries: Record<Dictionaries, FormattedOption[]>
): FormattedPDF => {
  const { Industry } = Dictionaries;

  const industryLabel = getSingleLabelFromDictionary(
    dictionaries[Industry],
    job.industry
  );

  const location = job?.location;

  const getLocation = location
    ? [location.city, location.state, location.country]
        .filter(Boolean)
        .join(', ')
    : '';

  const convertBooleanValue = (val: boolean | null | undefined): string =>
    val ? 'Yes' : 'No';

  return {
    availability: [
      {
        label: 'When do you want to start working?',
        value: job.startDate,
        type: 'text'
      }
    ],
    experience: [
      {
        label: `How many years of ${industryLabel} experience do you have?`,
        value: application.yearsOfIndustryExperience,
        type: 'text'
      }
    ],
    location: [
      {
        label: `Do you have a reliable way of commuting to this job location: ${getLocation}?`,
        value: convertBooleanValue(application.commuting),
        type: 'rounded'
      }
    ],
    legal: [
      {
        label: `Are you legally authorized to work in ${location?.country}?`,
        value: convertBooleanValue(application.workAuthorization),
        type: 'rounded'
      }
    ],
    salaryExpectations: [
      {
        label:
          'Are you comfortable with the salary range specified in the job description?',
        value: convertBooleanValue(application.okWithClientSalary),
        type: 'rounded'
      },
      {
        label: 'What is your salary expectation for this position?',
        value: application?.salaryExpectation || '-',
        type: 'text',
        show: !application.okWithClientSalary
      }
    ],
    availabilityForInterview: [
      {
        label: `Are you available for an interview on ${job?.startDate}?`,
        value: convertBooleanValue(application.clientInterviewAvailability),
        type: 'rounded'
      },
      {
        label:
          'What other dates are you available for a phone or video interview?',
        value: application.alternateInterviewDate,
        type: 'text',
        show: !application.clientInterviewAvailability
      }
    ]
  };
};

export default formatPDFData;
