import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'app/store';

import { selectFilters } from 'features/filters/filtersSelectors';
import { setPage } from 'features/filters/filtersSlice';

import { scrollToTop } from 'utils/scrollToTop';

const DEFAULT_INITIAL_PAGE = 0;

const usePagination = (): {
  currentPage: number;
  handlePageChange: (currentPage: number) => void;
  pageSize: number;
} => {
  const dispatch: AppDispatch = useDispatch();
  const { page, pageSize } = useSelector(selectFilters);

  const handlePageChange = useCallback(
    (currentPage: number) => {
      const currentPageForRequest = currentPage - 1;

      dispatch(setPage(currentPageForRequest));
      scrollToTop();
    },
    [dispatch]
  );

  const currentPage = (page ?? DEFAULT_INITIAL_PAGE) + 1;

  return {
    currentPage,
    handlePageChange,
    pageSize
  };
};

export { usePagination };
