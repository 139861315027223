import { post } from 'utils/api/api';

import {
  CheckEmailResponse,
  EmailConfig,
  LoginCredentials,
  LoginResponse
} from 'types/auth';

import { CHECK_EMAIL, LOGIN } from 'constants/endpoints';

class AuthService {
  async checkEmail(config: EmailConfig): Promise<CheckEmailResponse> {
    const response = await post(CHECK_EMAIL, config);
    return response;
  }

  async login(credentials: LoginCredentials): Promise<LoginResponse> {
    const response = await post(LOGIN, credentials);
    return response;
  }
}

export default new AuthService();
